const documentReady = (callback: () => void) => {
    if (
        document.readyState === 'complete' ||
        document.readyState === 'interactive'
    ) {
        setTimeout(callback, 1);
        return;
    }

    document.addEventListener('DOMContentLoaded', callback);
};

export default documentReady;
