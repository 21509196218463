import logMessage, { LogLevel } from '../helpers/logMessage';
import BookingFormConfig from '../types/BookingFormConfig';

import PageLocks from './PageLocks';
import WindowManager from './WindowManager';

type IFrameDictionary = Record<string, HTMLIFrameElement>;

class IFrameManager implements WindowManager {
    private frames: IFrameDictionary;

    private readonly pageLocks: PageLocks;

    constructor() {
        this.resizeIFrames = this.resizeIFrames.bind(this);

        this.frames = this.createIFramesForContainers(
            this.fetchContainersOrCreateOneOnTheFly()
        );
        this.pageLocks = new PageLocks();

        window.addEventListener('resize', this.resizeIFrames);
        this.resizeIFrames();
    }

    destroy() {
        window.removeEventListener('resize', this.resizeIFrames);
        this.removeIFramesFromContainers();

        Object.keys(this.frames).forEach((id) => this.hideWindow(id));

        this.frames = {};
    }

    showWindow(url: string, id?: string): string | undefined {
        const frameId = id ?? this.firstFrameId();

        const iframe = this.frames[frameId];
        if (!iframe) {
            logMessage(`No booking form found with id "${id}".`, LogLevel.WARN);
            return undefined;
        }

        iframe.src = url;
        iframe.style.display = 'block';

        this.pageLocks.lock(frameId);

        return frameId;
    }

    hideWindow(id?: string): string | undefined {
        const frameId = id ?? this.firstFrameId();

        const iframe = this.frames[frameId];
        if (!iframe) {
            logMessage(`No booking form found with id "${id}".`, LogLevel.WARN);
            return undefined;
        }

        this.pageLocks.unlock(frameId);

        iframe.style.display = 'none';

        /**
         * We're waiting a bit before unloading the page. This allows the app
         * to remove any `onbeforeunload` listeners. If we didn't do this,
         * closing the booking form overlay would trigger the onbeforeunload.
         */
        setTimeout(() => {
            iframe.src = '';
        }, 200);

        return frameId;
    }

    findIdForMessageEventSource(
        source: MessageEventSource
    ): string | undefined {
        return Object.keys(this.frames).find(
            (key) => this.frames[key]!.contentWindow === source
        );
    }

    findConfigForWindowId(id?: string): BookingFormConfig | undefined {
        const frameId = id ?? this.firstFrameId();
        const iframe = this.frames[frameId];
        if (!iframe) {
            logMessage(`No booking form found with id "${id}".`, LogLevel.WARN);
            return undefined;
        }

        if (!iframe.parentElement) {
            return undefined;
        }

        return iframe.parentElement.dataset as BookingFormConfig;
    }

    private fetchContainersOrCreateOneOnTheFly() {
        const containers = [
            ...document.querySelectorAll<HTMLElement>(
                '[data-lb-widget="booking-form"]'
            ),
        ];

        if (containers.length === 0) {
            const containerOnTheFly = document.createElement('div');
            containerOnTheFly.className = 'lb-booking-form-container';

            document.body.appendChild(containerOnTheFly);

            containers.push(containerOnTheFly);
        }

        return containers;
    }

    private firstFrameId(): string {
        return Object.keys(this.frames)[0]!;
    }

    private createIFramesForContainers(containers: Array<HTMLElement>) {
        const iframes: IFrameDictionary = {};

        containers.forEach((el) => {
            /**
             * Grab the dataset and use it as a config for the iframe.
             */
            const iframe = this.createIFrame();
            if (!iframe) {
                return;
            }

            el.appendChild(iframe);

            const id = el.dataset.id || 'default';
            iframes[id] = iframe;
        });

        return iframes;
    }

    private removeIFramesFromContainers() {
        Object.values(this.frames).forEach((el) =>
            el.parentNode?.removeChild(el)
        );
    }

    private createIFrame(): HTMLIFrameElement | undefined {
        const iframe = document.createElement('iframe');

        iframe.style.position = 'fixed';
        iframe.style.top = '0';
        iframe.style.left = '0';
        iframe.style.border = '0';
        iframe.style.zIndex = '9999999';
        iframe.style.display = 'none';
        iframe.referrerPolicy = 'no-referrer';

        return iframe;
    }

    private resizeIFrames() {
        const width = `${window.innerWidth}px`;
        const height = `${window.innerHeight}px`;

        Object.values(this.frames).forEach((el) => {
            const style = el.style;
            style.width = '100%';
            style.height = '100%';

            // eslint is complaining about the following lines, but it's fine.
            // eslint-disable-next-line no-param-reassign
            el.width = width;
            // eslint-disable-next-line no-param-reassign
            el.height = height;
        });
    }
}

export default IFrameManager;
