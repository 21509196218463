const BOOKING_FORM_URL = import.meta.env.VITE_BOOKING_FORM_URL as string;
const debugMode = !BOOKING_FORM_URL.includes('.app/');

export enum LogLevel {
    INFO = 'INFO',
    WARN = 'WARN',
    ERROR = 'ERROR',
}

const logMessage = (message: string, level: LogLevel = LogLevel.INFO) => {
    const logMessage = `[LetsBook] ${message}`;

    if (!debugMode && level !== LogLevel.ERROR) {
        return;
    }

    switch (level) {
        case LogLevel.INFO:
            console.info(logMessage);
            break;
        case LogLevel.WARN:
            console.warn(logMessage);
            break;
        case LogLevel.ERROR:
            console.error(logMessage);
            break;
    }
};

export default logMessage;
