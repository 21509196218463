const mapObject = <TIn extends Record<string, any>, TMapReturn>(
    object: TIn,
    map: (value: TIn[keyof TIn], key: keyof TIn) => TMapReturn
) =>
    Object.keys(object).reduce<Record<string, TMapReturn>>((result, key) => {
        // eslint-disable-next-line no-param-reassign
        result[key] = map(object[key], key as keyof TIn);

        return result;
    }, {}) as Record<keyof TIn, TMapReturn>;

export default mapObject;
