import BookingFormWidgetsManager from './BookingFormWidgetsManager';
import BookingFormConfig from './types/BookingFormConfig';

/**
 * When the anchor matches one of these subdomains, don't open in iframe.
 */
const defaultSubdomains = ['api', 'auth', 'dashboard'] as const;

const BOOKING_FORM_URL =
    (import.meta.env.VITE_BOOKING_FORM_URL as string) ?? '';

const domainRegex = new RegExp('/\\[tenantId](.*)\\/\\[locale]/');
const matches = BOOKING_FORM_URL.match(domainRegex);
const domain = matches?.[1] ?? '.letsbook.app';

const validTriggerSelectors = [
    'a[href^="#lb-booking-form"]',
    `a[href*="${domain}"]`,
];

class TriggerManager {
    private anchors?: NodeListOf<HTMLAnchorElement>;

    private bookingForms: BookingFormWidgetsManager;

    constructor(bookingForms: BookingFormWidgetsManager) {
        this.anchors = document.querySelectorAll(
            validTriggerSelectors.join(',')
        );
        this.bookingForms = bookingForms;

        this.handleAnchorClick = this.handleAnchorClick.bind(this);

        /**
         * We're using capture here to make sure we get the event before any
         * other event listeners on the anchor.
         *
         * This fixes an issue in Squarespace where they delay the event from
         * reaching our listener in a part of their code. This causes issues
         * with Safari which requires a user click to be immediately followed
         * by a `window.open` or it will block it.
         */
        this.anchors.forEach((el) =>
            el.addEventListener('click', this.handleAnchorClick, {
                capture: true,
            })
        );
    }

    destroy() {
        this.anchors?.forEach((el) =>
            el.removeEventListener('click', this.handleAnchorClick)
        );

        this.anchors = undefined;
    }

    private handleAnchorClick(event: MouseEvent) {
        const el = event.currentTarget as HTMLAnchorElement;
        if (this.shouldIgnoreAnchor(el)) {
            return;
        }

        event.preventDefault();
        event.stopPropagation();

        const id = this.findIdForAnchor(el);
        if (id) {
            const config = el.dataset as BookingFormConfig;
            config.id = config.id ?? id;

            this.bookingForms.showUsingConfig(config);

            return;
        }

        this.bookingForms.showUsingUrl(el.href);
    }

    private shouldIgnoreAnchor(anchor: HTMLAnchorElement): boolean {
        if (anchor.dataset.lbIgnore === 'true') {
            return true;
        }

        const url = anchor.href;

        const regexDomain = domain.replace(new RegExp('\\.', 'g'), '\\.');
        return !!defaultSubdomains.find((subDomain) => {
            const regex = new RegExp(`^https?://${subDomain}${regexDomain}`);

            return regex.test(url);
        });
    }

    private findIdForAnchor(anchor: HTMLAnchorElement) {
        const config = anchor.dataset as BookingFormConfig;
        if (config.id) {
            return config.id;
        }

        const partAfterHash = anchor.href.split('#')[1];
        if (!partAfterHash) {
            return undefined;
        }

        if (partAfterHash === 'lb-booking-form') {
            return 'default';
        }

        if (partAfterHash.startsWith('lb-booking-form_')) {
            return partAfterHash.split('_')[1];
        }

        return undefined;
    }
}

export default TriggerManager;
