import BookingFormWidgetsManager from './sdk/BookingFormWidgetsManager';
import TriggerManager from './sdk/TriggerManager';
import documentReady from './sdk/helpers/documentReady';
import isSafari from './sdk/helpers/isSafari';
import BookingFormConfig from './sdk/types/BookingFormConfig';
import LetsBook from './sdk/types/LetsBook';

/**
 * This should probably be expanded when more types of widgets are introduced.
 */
function createLetsBookInstance(): LetsBook {
    const bookingFormsManagers = new BookingFormWidgetsManager({
        /**
         * Safari does not allow settings cookies from iframes, so we need to
         * open the booking form in a new tab.
         */
        openInNewTab: isSafari(),
    });
    const triggerManager = new TriggerManager(bookingFormsManagers);

    return {
        show: (config?: BookingFormConfig) =>
            bookingFormsManagers.showUsingConfig(config),
        hide: () => bookingFormsManagers.hide(),
        destroy: () => {
            bookingFormsManagers.destroy();
            triggerManager.destroy();

            if (typeof window.LetsBook !== 'undefined') {
                window.LetsBook = undefined;
            }

            return () => initialize();
        },
    };
}

function initialize() {
    if (typeof window.LetsBook !== 'undefined') {
        window.LetsBook.destroy();
    }

    window.LetsBook = createLetsBookInstance();
    window.dispatchEvent(new Event('lb-ready'));
}

documentReady(initialize);
