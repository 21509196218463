import filterObjectProperties from './helpers/filterObjectProperties';
import logMessage, { LogLevel } from './helpers/logMessage';
import mapObject from './helpers/mapObject';
import objectToQueryString from './helpers/objectToQueryString';
import BookingFormConfig from './types/BookingFormConfig';
import PostMessageMessage from './types/PostMessageMessage';
import ExternalWindowManager from './windows/ExternalWindowManager';
import IFrameManager from './windows/IFrameManager';
import WindowManager from './windows/WindowManager';

const BOOKING_FORM_URL = import.meta.env.VITE_BOOKING_FORM_URL as string;

type Options = {
    openInNewTab?: boolean;
};

class BookingFormWidgetsManager {
    private readonly windows: WindowManager;

    private currentBookingFormId: string | undefined = undefined;

    constructor({ openInNewTab = false }: Options = {}) {
        this.handleMessageReceived = this.handleMessageReceived.bind(this);

        this.windows = openInNewTab
            ? new ExternalWindowManager()
            : new IFrameManager();

        window.addEventListener('message', this.handleMessageReceived);
    }

    destroy() {
        window.removeEventListener('message', this.handleMessageReceived);

        this.windows.destroy();
    }

    showUsingConfig(config?: BookingFormConfig) {
        const configFromWindow = this.windows.findConfigForWindowId(config?.id);

        const mergedConfig = { ...configFromWindow, ...config };
        const url = this.compileUrl(mergedConfig);

        if (!url) {
            logMessage(
                'Container or trigger lacks required data- attributes.',
                LogLevel.ERROR
            );
            return;
        }

        this.showUsingUrl(url, mergedConfig.id);
    }

    showUsingUrl(url: string, id?: string) {
        const frameId = this.windows.showWindow(url, id);

        this.currentBookingFormId = frameId;

        window.dispatchEvent(
            new CustomEvent('lb-show', { detail: { id: frameId } })
        );
    }

    hide() {
        const frameId = this.windows.hideWindow(this.currentBookingFormId);
        this.currentBookingFormId = undefined;

        window.dispatchEvent(
            new CustomEvent('lb-hide', { detail: { id: frameId } })
        );
    }

    private compileUrl(config: BookingFormConfig): string | undefined {
        if (!config.tenantId) {
            return undefined;
        }

        const localeSegment = config.language
            ? `/${config.language.replace('_', '-').toLowerCase()}`
            : '/unknown';

        let url = BOOKING_FORM_URL.replace(
            '[tenantId]',
            config.tenantId
        ).replace('/[locale]', localeSegment);

        if (config.step) {
            url += `/${config.step}`;
        }

        const handledKeys = ['lbWidget', 'tenantId', 'language', 'step'];

        /**
         * Pass everything else as query params
         */
        const queryParams = filterObjectProperties(
            config,
            (key) => handledKeys.indexOf(key) === -1
        );

        const queryParamsWithConvertedBooleans = mapObject(
            queryParams,
            (value) => {
                if (value === 'true') {
                    return '1';
                }

                if (value === 'false') {
                    return '0';
                }

                return value;
            }
        );

        return `${url}?${objectToQueryString(
            queryParamsWithConvertedBooleans
        )}`;
    }

    private handleMessageReceived(event: MessageEvent<PostMessageMessage>) {
        if (!event.source) {
            logMessage('No source frame found for message.', LogLevel.WARN);
            return;
        }

        const sourceFrameId = this.windows.findIdForMessageEventSource(
            event.source
        );

        if (!sourceFrameId) {
            logMessage(
                `Received message from unknown source frame "${sourceFrameId}".`,
                LogLevel.WARN
            );
            return;
        }

        switch (event.data.type) {
            case 'lb-close':
            case 'lb-completed':
                this.hide();
                break;

            default:
                window.dispatchEvent(
                    new CustomEvent(event.data.type, {
                        detail: {
                            ...event.data,
                            id: sourceFrameId,
                        },
                    })
                );
        }
    }
}

export default BookingFormWidgetsManager;
