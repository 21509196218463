import logMessage, { LogLevel } from '../helpers/logMessage';
import BookingFormConfig from '../types/BookingFormConfig';

import WindowManager from './WindowManager';

type WindowDictionary = Record<string, Window>;

class ExternalWindowManager implements WindowManager {
    private windows: WindowDictionary = {};

    // Windows do not use containers, but they can be used to configure the booking form.
    private readonly configContainers: Record<string, BookingFormConfig>;

    constructor() {
        this.configContainers = this.findConfigurationsFromContainers();
    }

    destroy() {
        Object.keys(this.windows).forEach((id) => this.hideWindow(id));

        this.windows = {};
    }

    showWindow(url: string, id?: string): string | undefined {
        const newWindow = window.open(url, '_blank');
        if (!newWindow) {
            logMessage('Failed to open external window.', LogLevel.ERROR);
            return undefined;
        }

        const windowId = id ?? 'default';

        this.windows[windowId] = newWindow;

        return windowId;
    }

    hideWindow(id?: string): string | undefined {
        const windowId = id ?? this.firstWindowId();

        const openWindow = this.windows[windowId];
        if (!openWindow) {
            logMessage(`No booking form found with id "${id}".`, LogLevel.WARN);
            return undefined;
        }

        delete this.windows[windowId];

        openWindow.close();

        return windowId;
    }

    findIdForMessageEventSource(
        source: MessageEventSource
    ): string | undefined {
        return Object.keys(this.windows).find(
            (key) => this.windows[key] === source
        );
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    findConfigForWindowId(id?: string): BookingFormConfig | undefined {
        const windowId = id ?? this.firstWindowId();
        const config = this.configContainers[windowId];
        if (!config) {
            logMessage(`No booking form found with id "${id}".`, LogLevel.WARN);
            return undefined;
        }

        return config;
    }

    private firstWindowId(): string {
        return Object.keys(this.windows)[0]!;
    }

    private findConfigurationsFromContainers() {
        const containers = [
            ...document.querySelectorAll<HTMLElement>(
                '[data-lb-widget="booking-form"]'
            ),
        ];

        return containers.reduce(
            (config, el) => {
                const id = el.dataset.id || 'default';

                return { ...config, [id]: el.dataset };
            },
            {} as Record<string, BookingFormConfig>
        );
    }
}

export default ExternalWindowManager;
