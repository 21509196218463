class PageLocks {
    private locks: Array<string> = [];

    lock(lockId: string) {
        this.locks.push(lockId);

        // Filters to unique
        this.locks = this.locks.filter((id, index) => {
            return this.locks.indexOf(id) === index;
        });

        if (this.locks.length === 1) {
            PageLocks.lockHostPage();
        }
    }

    unlock(lockId: string) {
        this.locks = this.locks.filter((id) => lockId !== id);

        if (this.locks.length === 0) {
            PageLocks.unlockHostPage();
        }
    }

    private static lockHostPage() {
        document.documentElement.style.overflow = 'hidden';
    }

    private static unlockHostPage() {
        document.documentElement.style.overflow = 'visible';
    }
}

export default PageLocks;
