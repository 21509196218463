type PartOfObject<K extends string, T> = Partial<Record<K, T>>;

/**
 * TODO: Remove the 'as' castings as they shouldn't be necessary
 */
export default function filterObjectProperties<T, K extends string>(
    obj: PartOfObject<K, T>,
    predicate: (key: K, value: T) => boolean
) {
    return Object.keys(obj).reduce<PartOfObject<K, T>>((previousValue, key) => {
        const typedKey = key as K;
        const value = obj[typedKey] as T;

        if (predicate(typedKey, value)) {
            return { ...previousValue, [typedKey]: value };
        }

        return previousValue;
    }, {});
}
